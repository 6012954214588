@tailwind base;
@tailwind components;
@tailwind utilities;
 
@font-face {
  font-family: "Shabnam";
  src: url('/assets/font/Shabnam.ttf') format('truetype');
  font-weight: 400;
  font-size: 16px;
}
@font-face {
  font-family: "Shabnam";
  src: url('/assets/font/Shabnam.ttf') format('truetype');
  font-weight: 500;
  font-size: 18px;
}
@font-face {
  font-family: "Shabnam";
  src: url('/assets/font/Shabnam-thin.ttf') format('truetype');
  font-weight: 100;
  font-size: 10px;
}
@font-face {
  font-family: "Shabnam";
  src: url('/assets/font/Shabnam-thin.ttf') format('truetype');
  font-weight: 200;
  font-size: 12px;
}
@font-face {
  font-family: "Shabnam";
  src: url('/assets/font/Shabnam-light.ttf') format('truetype');
  font-weight: 300;
  font-size: 14px;
}
@font-face {
  font-family: "Shabnam";
  src: url('/assets/font/Shabnam-Medium.ttf') format('truetype');
  font-weight: 500;
  font-size: 16px;
}
@font-face {
  font-family: "Shabnam";
  src: url('/assets/font/Shabnam-Medium.ttf') format('truetype');
  font-weight: 600;
  font-size: 18px;
}
@font-face {
  font-family: "Shabnam";
  src: url('/assets/font/Shabnam-Bold.ttf') format('truetype');
  font-weight: 700;
  font-size: 20px;
}
@font-face {
  font-family: "Shabnam";
  src: url('/assets/font/Shabnam-Bold.ttf') format('truetype');
  font-weight: 800;
  font-size: 22px;
}



@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 270 91% 56%;
    --primary-foreground: 355.7 100% 97.3%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 270 91% 56%;
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 0 0% 95%;
    --card: 24 9.8% 10%;
    --card-foreground: 0 0% 95%;
    --popover: 0 0% 9%;
    --popover-foreground: 0 0% 95%;
    --primary: 270 91% 56%;
    --primary-foreground: 355.7 100% 97.3%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 15%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 270 91% 56%;
  }
}



 
@layer base {
  * {
    @apply border-border;
  }
  html {
    font-family: "Shabnam", system-ui, sans-serif;
  }
  body {
    @apply bg-background text-foreground;
  }
}

::-webkit-scrollbar {
  width: 6px;
  background-color: hsl(var(--card));
}

::-webkit-scrollbar-thumb {
  background-color: hsl(var(--primary));
  border-radius: 5px;
} 